//===常用颜色定义
@g0:                 #000;
@g3:                 #333;
@g6:                 #666;
@g9:                 #999;
@ga:                 #aaa;
@gc:                 #ccc;

@w:                  #fff;
//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  'Microsoft YaHei','WenQuanYi Micro Hei','Helvetica Neue',Verdana,Arial,Helvetica,SimHei,sans-serif;

//** Unit-less `line-height` for use in components like buttons.
@lh-base:         		  150%;
@lh-big:                  200%;
@lh-small:                120%;

/* 以宽度为1200px；html的font-size为8px 的px对应关系
 * --12px---14px---16px---18px---20px---22px---24px--26px---28px----30px---32px--34px----36px
 */
@fs:10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px;
@fs0:10px;
@fs1:12px;
@fs2:14px;
@fs-small-m:14px;
@fs3:16px;
@fs-small:16px;
@fs4:18px;
@fs-base:18px;
@fs5:20px;
@fs6:22px;
@fs7:24px;
@fs-big:24px;
@fs8:26px;
@fs9:28px;
@fs10:30px;
@fs-big-m:30px;
@fs11:32px;
@fs12:34px;
@fs13:36px;


//===可配置部分
@themeColor:         #113281;
@themeColorRgb: 96 57 57;
// @subColor:           #33cde5;
@subColor:           @themeColor;
@errorColor:         #fe0000;
@succColor:          #8db349;

@themeYellow:        #fdac00;