.comp_radio{
	width:auto;
	input{
		display:none!important;
	}
	.one{
		float:left;
		cursor:pointer;
		margin-top:7px;
	}
	.two{
		float:left;
		cursor:pointer;
		margin-top:7px;
		margin-left:50px;
	}
	.sexRadio{
		float:left;
		.f(@fs-base,@g3);
		height:25px;
		line-height:25px;
	}
	.sexCheck{
		float:left;
		margin-left:15px;
		.border-radius(2px);
		height:23px;
		width:23px;
		border:1px solid #ccc;
		text-align:center;
		font-size:0px;
		line-height:23px;
		img{
			margin-top:20%;
			width:92%;
			display:none;
		}
	}
	.active{
		.sexCheck{
			background-color:@themeColor;
			border:1px solid @themeColor;
			img{
				.inline-block;
			}
		}
	}
}
.comp_select{
	.comp_select(@g0);
}
.comp_select(@tg:@themeColor,@w:230px,@h:35px){
	position:relative;
	float:left;
	width:@w;
	height:@h;
	.floor(2);
	cursor:default;
	.noTxt{
		.f(@fs-small,@g9);
		line-height:@h;
	}
	>.c_input{
		border:1px solid @tg;
		margin-left:0px;
		margin-right:0px;
		padding-left:10px;
		padding-right:10px;
		text-align:center;
		height:@h;
		.f(@fs-small,@tg);
		// font-weight:bold;
		line-height:@h;
		.toh;
		.border-radius(@h);
	}
	>.comp_down{
		position:relative;
		.floor(1);
		overflow-y:auto;
		display:none;
		border:1px solid @tg;
		// .box-shadow(lighten(@tg,22%),0px,0px,3px);
		background-color:#fff;
		background-color:rgba(255,255,255,.5);
		margin-top:1px;
		.border-radius(15px);
		>ul{
			position:relative;
			.floor(1);
			max-height:234px;
			*height:234px;
			overflow-y:auto;
			>li{
				position:relative;
				.floor(1);
				.transition(.1s);
				text-align:center;
				padding-left:10px;
				padding-right:10px;
				padding-top:15px;
				padding-bottom:15px;
				// height:45px;
				background-color:transparent;
				.f(@fs-small-m,@tg,20px);
				// .toh;
			}
			>._preChoose{
				background-color:@tg;
				color:#fff;
			}
		}
	}
	>.ico_drop_down{
		position:absolute;
		top:15px;
		right:15px;
		height:8px;
		width:14px;
		background-image:url('../images/ico/arrow_down0.png');
		background-repeat:no-repeat;
	}
	._search{
		outline:none;
		.width;
		margin:10px 0px 15px 0px;
		padding:0px 10px;
		height:35px;
		line-height:35px;
		border:1px solid #f1f1f1;
		background-color:#f1f1f1;
		.border-radius(2px);
		&:focus{
			border:1px solid #eee;
			.box-shadow(#fff);
			background-color:#fff;
		}
	}
	&.active{
		>.c_input{
			border:1px solid @tg;
			// .box-shadow(lighten(@tg,22%),0px,0px,3px);
		}
		>.comp_down{
			display:block;
		}
	}
}
.comp_tip{
	position:fixed;
	left:50%;
	top:35%;
	.floor(4);
}
.comp_noticeTip{
	.noWrap;
	padding: 6px 22px;
	text-align: center;
	.border-radius(5px);
	.box-shadow(@g9,2px,2px,5px);
	background: url('../images/login/tip.png') no-repeat;
	background-position: 22px 17.5px;
	background-color: @w;
	padding-left: 38px;
	>img{
		float: left;
		display: inline-block;
	}
	.comp_txt{
		display: inline-block;
		margin-left: 10px;
		.f(@fs3,@g3,40px);
	}
}
.comp_rightTip{
	.noWrap;
	padding: 6px 22px;
	text-align: center;
	.border-radius(5px);
	.box-shadow(@g9,2px,2px,5px);
	background: url('../images/login/success.png') no-repeat;
	background-position: 22px 17.5px;
	background-color: @w;
	padding-left: 38px;
	>img{
		float: left;
		display: inline-block;
	}
	.comp_txt{
		display: inline-block;
		margin-left: 10px;
		.f(@fs3,@g3,40px);
	}
}

.comp_errorTip{
	background-color: @w;
	.box-shadow(@g9,2px,2px,5px);
	padding: 6px 22px;
	.border-radius(5px);
	.noWrap;
	text-align: center;
	>img{
		float: left;
		display: inline-block;
	}
	background: url('../images/login/danger.png') no-repeat;
	background-position: 22px 17.5px;
	background-color: @w;
	padding-left: 38px;
	.comp_txt{
		display: inline-block;
		margin-left: 10px;
		.f(@fs3,@g3,40px);
	}
}

.comp_checkbox{
	.clear;
	cursor:pointer;
	input{
		display:none;
	}
	.comp_label{
		.fl;
		.f(@fs-small,@g3,21px);
	}
	.comp_check{
		.fl;
		height:19px;
		width:19px;
		.border-radius(2px);
		background-color:#fff;
		border:1px solid @gc;
		margin-left:10px;
		font-size:0px;
		>img{
			width:100%;
			display:none;
			margin-top:15%;
		}
	}
}
.comp_input{
	margin-left:1px;
	margin-right:1px;
	height:38px;
	line-height:38px;
	background-color:#fff;
	border:1px solid @gc;
	.border-radius(4px);
	.transition(.22s);
	>div{
		position:relative;
		margin-right:10px;
		margin-left:10px;
		height:100%;
	}
	textarea,input{
		position:absolute;
		left:0px;
		top:0px;
		height:100%;
		width:100%;
		outline:none;
		.f(@fs-small,@g0,40px);
		z-index:2;
		border:none!important;
		margin:0px!important;
		padding:0px!important;
		background-color:transparent;
		&:focus{
			.box-shadow(none);
		}
	}
	.placeholder{
		position:absolute;
		height:100%;
		width:100%;
		overflow:hidden;
		left:0px;
		top:0px;
		text-align:left;
		.f(@fs-small,@gc,40px);
	}
	&.focus{
		border:1px solid @themeColor;
		.box-shadow(lighten(@themeColor,22%),0px,0px,3px);
	}
}

.comp_loading{
	position: fixed;
	top: 35%;
	left: 50%;
	.floor(4);
	width: 98px;
	height: 98px;
	display: none;
	.rgba(0,0,0,.5);
	margin-top: -45px;
	margin-left: -55px;
	.border-radius(10px);
	.spin_img {
		width: 50px;
		height: 50px;
		display: block;
		margin: 21px auto;
	}
	.spinner {
		position: relative;
		margin: 15px auto;
		width: 68px;
		height: 68px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../images/com/loading_68.gif');
	}
	.container1 > div, .container2 > div, .container3 > div {
		width: 10px;
		height: 10px;
		background-color:#fff;
		border-radius: 100%;
		position: absolute;
		-webkit-animation: bouncedelay 1.2s infinite ease-in-out;
		animation: bouncedelay 1.2s infinite ease-in-out;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	
	.spinner .spinner-container {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	
	.container2 {
		-webkit-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
	}
	
	.container3 {
		-webkit-transform: rotateZ(90deg);
		transform: rotateZ(90deg);
	}
	
	.circle1 { top: 0; left: 0; }
	.circle2 { top: 0; right: 0; }
	.circle3 { right: 0; bottom: 0; }
	.circle4 { left: 0; bottom: 0; }
	
	.container2 .circle1 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}
	
	.container3 .circle1 {
		-webkit-animation-delay: -1.0s;
		animation-delay: -1.0s;
	}
	
	.container1 .circle2 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}
	
	.container2 .circle2 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}
	
	.container3 .circle2 {
		-webkit-animation-delay: -0.7s;
		animation-delay: -0.7s;
	}
	
	.container1 .circle3 {
		-webkit-animation-delay: -0.6s;
		animation-delay: -0.6s;
	}
	
	.container2 .circle3 {
		-webkit-animation-delay: -0.5s;
		animation-delay: -0.5s;
	}
	
	.container3 .circle3 {
		-webkit-animation-delay: -0.4s;
		animation-delay: -0.4s;
	}
	
	.container1 .circle4 {
		-webkit-animation-delay: -0.3s;
		animation-delay: -0.3s;
	}
	
	.container2 .circle4 {
		-webkit-animation-delay: -0.2s;
		animation-delay: -0.2s;
	}
	
	.container3 .circle4 {
		-webkit-animation-delay: -0.1s;
		animation-delay: -0.1s;
	}
	
	@-webkit-keyframes bouncedelay {
		0%, 80%, 100% { -webkit-transform: scale(0.0) }
		40% { -webkit-transform: scale(1.0) }
	}
	
	@keyframes bouncedelay {
		0%, 80%, 100% {
			transform: scale(0.0);
			-webkit-transform: scale(0.0);
		} 40% {
			transform: scale(1.0);
			-webkit-transform: scale(1.0);
		}
	}
}