@paging-border-color:#ccc;
@paging-bg-color:#faf9f9;
@paging-color:#999;
@paging-active-border-color:darken(#fdac00,10%);
@paging-active-bg-color: @subColor;
@paging-active-color:#fff;

.paging{
	padding: 50px 0;
//  .block-center(@width-wrap);
	
	text-align: center;
	li{
		.inline-block();
		ul{
			.inline-block();
		}
		a,span{
			.inline-block();
			border-radius: 5px;
			padding: 8px 13px;
			border:1px solid @paging-border-color;
			background: @paging-bg-color;
			margin-right: 8px;
			margin-left: 8px;
			font-size: 14px;
			font-weight:normal;
			color:@paging-color;
			.transition(.1s);
		}
		a:hover,&.active a{
			color: @paging-active-color;
			background-color: @paging-active-bg-color;
			border:1px solid @paging-active-bg-color;
			text-decoration: none;
		}
	}
}