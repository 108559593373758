@import "base/variables.less";
@import "base/normalize.less";
@import "base/fun.less";
@import "base/base.less";
@import "base/plugs.less";
@import "base/paging.less";
body{
	min-height: 0px;
}
.wrapper{
	position: relative;
}
.body{
	width:100%;
	background-color:#f5f5f6;
	padding-bottom:10px;
	.clear;
}
.banner{
	.pr;
	display: block;
	.banner_img{
		position: relative;
		display: block;
		left:50%;
		margin-left:-960px;
	}
	.cover{
		position: absolute;
		left:0px;
		right:0px;
		top:0px;
		bottom:0px;
	}
	.btn_consult{
		.pa;
		left: 50%;
		bottom: 0px;
		height: 66px;
		width: 1920px;
		margin-left: -960px;
		background-image: url('../images/change_doc/fun/qna_banner_bottom.png');
		>span{
			.pa;
			top: 50%;
			left: 50%;
			width: 120px;
			height: 33px;
			margin-top: -16.5px;
			margin-left: 444.5px;
			background-image: url('../images/change_doc/fun/qna_btn_txt.png');
			.transition(.22s);
			&:hover{
				background-image: url('../images/change_doc/fun/qna_btn_txt_hover.png');
			}
		}
	}
}
/*.banner{
	.pr;
	display: block;
	.banner_img{
		position: relative;
		display: block;
		left:50%;
		margin-left:-960px;
	}
	.cover{
		position: absolute;
		left:0px;
		right:0px;
		top:0px;
		bottom:0px;
	}
	.btn_consult{
		.pa;
		left: 50%;
		top: 235px;
		width: 120px;
		height: 33px;
		display: block;
		margin-top: -16.5px;
		margin-left: -562px;
		background-image: url('../images/change_doc/fun/qna_btn_txt.png');
		.transition(.22s);
		&:hover{
			background-image: url('../images/change_doc/fun/qna_btn_txt_hover.png');
		}
	}
}*/
// 智能在线解答主页
.qna_home{
	.qiuck_links{
		.clear;
		width: 1129px;
		margin: 0 auto;
		margin-top: 20px;
		padding: 29px 0px;
		padding-left: 1px;
		background-color: @w;
		>li{
			.fl;
			width: 188px;
			text-align: center;
			.icon_com{
				width: 56px;
				height: 56px;
				.transition(.05s);
				background-size: auto;
				background-position: center;
				background-repeat: no-repeat;
			}
			.txt{
				margin-top: 17px;
				.transition(.22s);
				font-weight: bold;
				.f(@fs3,@themeColor,100%);
			}
			&:hover{
				opacity: .8;
			}
		}
		&.l_10{
			width: 1130px;
			padding-right: 0px;
			>li{
				width: 113px;
			}
		}
		&.l_9{
			width: 1125px;
			padding-left: 2px;
			padding-right: 3px;
			>li{
				width: 125px;
			}
		}
		&.l_8{
			width: 1128px;
			padding-left: 1px;
			padding-right: 1px;
			>li{
				width: 141px;
			}
		}
		&.l_7{
			width: 1127px;
			padding-left: 1px;
			padding-right: 2px;
			>li{
				width: 161px;
			}
		}
		&.l_6{
			>li{
				width: 188px;
			}
		}
		&.l_5{
			>li{
				width: 225px;
			}
		}
		&.l_4{
			width: 1128px;
			padding-left: 1px;
			padding-right: 1px;
			>li{
				width: 282px;
			}
		}
		&.l_3{
			width: 1128px;
			padding-left: 1px;
			padding-right: 1px;
			>li{
				width: 376px;
			}
		}
		&.l_2{
			width: 1130px;
			>li{
				width: 565px;
			}
		}
		&.l_1{
			width: 1130px;
			>li{
				width: 1130px;
			}
		}
	}
	.icon_search{
		height: 18px;
		width: 18px;
		background-image: url('../images/qna/ico_search.png');
	}
	.icon_sticker{
		height: 30px;
		width: 30px;
		background-image: url('../images/qna/ico_sticker.png');
	}
	.icon_addr{
		width: 16px;
		height: 18px;
		background-image: url('../images/qna/ico_addr.png');
	}
	.icon_id{
		width: 20px;
		height: 17px;
		background-image: url('../images/qna/ico_id.png');
	}
	.icon_tim{
		width: 17px;
		height: 17px;
		background-image: url('../images/qna/ico_tim.png');
	}
	.icon_see_num{
		width: 18px;
		height: 13px;
		background-image: url('../images/qna/ico_see_num.png');
	}
	.icon_quest{
		width: 24px;
		height: 25px;
		background-image: url('../images/change_doc/fun/qna_ico_quest.png');
	}
	.icon_unRead{
		position: absolute;
		top: -8px;
		width: 50px;
		height: 50px;
		background-image: url('../images/qna/unRead.png');
	}
	.icon_ans{
		width: 24px;
		height: 25px;
		background-image: url('../images/change_doc/fun/qna_ico_ans.png');
	}
	.icon_arrow_down{
		height: 9px;
		width: 9px;
		.transition(.05s);
		background-image: url('../images/qna/ico_arrow_down.png');
	}
	.icon_arrow_up{
		height: 9px;
		width: 9px;
		background-image: url('../images/qna/ico_arrow_up.png');
	}
	.qna{
		width: 1130px;
		margin: 0 auto;
		margin-top: 20px;
		.qna_header{
			width: 1070px;
			width: 1014px;
			padding: 0px 58px;
			background-color: @w;
		}
		.qna_tabs_con{
			font-size: 0px;
			.pr;
			>li{
				font-size: 0px;
			}
		}
		.search{
			width: 100%;
			height: 32px;
			font-size: 0px;
			.transition(.22s);
			padding: 30px 0px;
			text-align: center;
			border-bottom: 1px solid #dedede;
			>form{
				.inline-block;
				.clear;
			}
		}
		.fixed_search{
			position: fixed;
			top: -92px;
			.transition(.35s);
			background-color: #fff;
			left: 50%;
			width: 1130px;
			margin-left: -565px;
			text-align: center;
			padding: 30px 0px;
			font-size: 0px;
			.floor(3);
			.box-shadow(rgba(158,158,158,.2),2px,2px,8px);
			border-bottom: 1px solid #ccc\9;
			>form{
				.inline-block;
				.clear;
			}
		}
		.comp_input{
			position: relative;
			float: left;
			width: 550px;
			height: 35px;
			.border-radius(3px);
			.icon_search{
				position: absolute;
				left: 10px;
				top: 8px;
			}
			>div{
				height: 35px;
				margin-left: 35px;
				input{
					height: 35px;
					.f(@fs1,#333333,35px);
				}
				.placeholder{
					height: 35px;
					.f(@fs1,#999999,35px);
				}
			}
			&.focus{
				border: 1px solid #bbb;
				.box-shadow(lighten(#bbb,22%),0px,0px,3px);
			}
		}
		.btn_search{
			float: left;
			width: 98px;
			height: 35px;
			border: 1px solid @themeColor;
			background-color: @themeColor;
			.border-radius(3px);
			font-size: @fs3;
			line-height: 28px;
			display: inline-block;
			color: @w;
			margin-left: 17px;
			outline: none;
			.transition(.22s);
			&:hover{
				.opacity(.8);
			}
		}
		.qna_num{
			height: 35px;
			.f(@fs2,#73777a,35px);
			text-align: center;
			width: 100%;
			margin-top: 30px;
			background-color: #e6f9fc;
			cursor: pointer;
		}
		.qna_tabs{
			margin-top: 25px;
			width: 1012px;
			margin: 25px auto 0px;
			border: 1px solid @themeColor;
			font-size: 0px;
			.border-radius(6px);
			overflow: hidden;
			.clear;
			>li{
				position: relative;
				float: left;
				width: 506px;
				color: @themeColor;
				height: 33px;
				line-height: 32px;
				font-size: @fs2;
				text-align: center;
				cursor: pointer;
				.transition(.1s);
				letter-spacing: 2px;
				background-color: #fff;
				.icon_hot{
					position: relative;
					top: -8px;
					left: 2px;
					width: 47px;
					height: 7px;
					.transition(.22s);
					background-image: url('../images/qna/hot_tip.png');
				}
				&:hover{
					background-color: #e7eaf1;
				}
				&.active{
					color: #fff;
					background-color: @themeColor;
					.icon_hot{
						background-image: url('../images/qna/hot_tip_w.png');
					}
				}
			}
		}
		.qna_list{
			>li{
				padding: 30px 58px;
				padding-bottom: 15px;
				background-color: @w;
				margin-bottom: 10px;
				.qna_info{
					.clear;
					cursor: pointer;
					.f(@fs2,#666666,30px);
					.user{
						float: left;
						margin-right: 41px;
						.icon_sticker{
							float: left;
							margin-right: 10px;
						}
					}
					.addr{
						float: left;
						margin-right: 41px;
						.icon_addr{
							float: left;
							margin-top: 6px;
							margin-right: 10px;
						}
					}
					.id{
						float: left;
						margin-right: 41px;
						.icon_id{
							float: left;
							margin-top: 6px;
							margin-right: 10px;
						}
					}
					.tim{
						float: left;
						margin-right: 41px;
						.icon_tim{
							float: left;
							margin-top: 6px;
							margin-right: 10px;
						}
					}
					.unRead{
						position: relative;
						float: left;
					}
					.see_num{
						float: right;
						.icon_see_num{
							float: left;
							margin-top: 7px;
							margin-right: 10px;
						}
						&.active{
							.icon_see_num{
								background-image: url('../images/qna/ico_see_num_active.png');
							}
						}
					}
				}
				.qna_quest{
					position: relative;
					margin-top: 23px;
					padding-bottom: 15px;
					.clear;
					.icon_quest{
						position: relative;
						float: left;
						display: block;
						cursor: pointer;
					}
					.txt{
						position: relative;
						float: left;
						margin-left: 15px;
						width: 971px;
						cursor: pointer;
						.f(@fs3,#666666,25px);
						text-align: justify;
						.transition(.05s);
						text-justify: inter-ideograph;
						span{
							width: 866px;
							display: inline-block;
						}
						span:hover{
							color: @themeColor;
						}
					}
					.btn_seeMore{
						position: absolute;
						top: 0px;
						right: 0px;
						width: 86px;
						height: 28px;
						outline: none;
						.f(@fs1,#ff8500);
						.transition(.05s);
						.border-radius(2px);
						background-color: #ffdeba;
						border: 1px solid #ff8500;
					}
					.btn_unAns{
						float: right;
						width: 86px;
						height: 28px;
						background-color: #f7b3b3;
						border: 1px solid #ff0000;
						.border-radius(2px);
						.f(@fs1,#ff0000,28px);
						text-align: center;
						outline: none;
						.transition(.05s);
					}
				}
				.qna_answer{
					position: relative;
					padding-bottom: 15px;
					display: none;
					.clear;
					.icon_ans{
						float: left;
						cursor: pointer;
					}
					.txt{
						float: left;
						width: 971px;
						margin-left: 15px;
						// .f(@fs3,#666,25px);
						color: #666!important;
						font-size: 14px!important;
						line-height: 25px!important;
						font-family: 'Microsoft YaHei', 'WenQuanYi Micro Hei', 'Helvetica Neue', Verdana, Arial, Helvetica, SimHei, sans-serif!important;
						* {
							color: #666!important;
							font-size: 16px!important;
							line-height: 25px!important;
							font-family: 'Microsoft YaHei', 'WenQuanYi Micro Hei', 'Helvetica Neue', Verdana, Arial, Helvetica, SimHei, sans-serif!important;
						}
						text-align: justify;
						text-justify: inter-ideograph;
						.temple_video{
							.clear;
							width: 620px;
							background-color: #fff;
							border-radius: 6px;
							padding-bottom: 25px;
							.comp_video{
								font-size: 0px;
								height: 340px;
								margin-left: 15px;
								margin-right: 15px;
								margin-top: 15px;
								>video{
									.width;
									height: 340px;
								}
							}
							div[class^="pausecenterch"]{
								top: 50%!important;
								left: 50%!important;
								margin-top: -40px!important;
								margin-left: -40px!important;
							}
							.t_txt{
								margin-top: 25px;
								margin-left: 15px;
								margin-right: 15px;
							}
							.t_see_more{
								margin-left: 15px;
								font-size: 14px!important;
								color: #00c1de!important;
								line-height: 30px!important;
								&:hover{
									opacity: .8;
								}
							}
						}
						.temple_auto{
							.clear;
							width: 620px;
							background-color: #fff;
							border-radius: 6px;
							padding-bottom: 25px;
							.comp_auto{
								font-size: 0px;
								margin-top: 25px;
								margin-left: 15px;
								margin-right: 15px;
							}
							.t_txt{
								margin-top: 15px;
								margin-left: 15px;
								margin-right: 15px;
							}
							.t_see_more{
								margin-left: 15px;
								font-size: 14px!important;
								color: #00c1de!important;
								line-height: 30px!important;
								&:hover{
									opacity: .8;
								}
							}
						}
						.temple_imgs{
							.clear;
							width: 620px;
							background-color: #fff;
							border-radius: 6px;
							padding-bottom: 25px;
							.comp_imgs{
								.clear;
								font-size: 0px;
								margin-top: 15px;
								margin-left: 15px;
								margin-right: 5px;
								>div{
									.fl;
									width: 190px;
									height: 130px;
									margin-right: 10px;
									border-radius: 2px;
									background-size: cover;
									background-color: green;
									background-position: center;
									background-repeat: no-repeat;
								}
							}
							.t_txt{
								margin-top: 15px;
								margin-left: 15px;
								margin-right: 15px;
							}
							.t_see_more{
								margin-left: 15px;
								font-size: 14px!important;
								color: #00c1de!important;
								line-height: 30px!important;
								&:hover{
									opacity: .8;
								}
							}
						}
					}
				}
				&.active{
					.qna_quest{
						.txt{
							color: @themeColor;
						}
						.btn_seeMore{
							background-color: #baeef6;
							border: 1px solid #59bfdb;
							color: #00c1de;
							.icon_arrow_down{
								background-image: url('../images/qna/ico_arrow_up.png');
							}
						}
					}
				}
			}
		}
		.nodata{
			width: 100%;
			padding-bottom: 75px;
			padding-top: 75px;
			text-align: center;
			background-color: #fff;
			.txt{
				font-size: 22px;
				color: #bbb;
				letter-spacing: 3px;
			}
		}
		.noSearData{
			width: 100%;
			padding-top: 50px;
			padding-bottom: 98px;
			text-align: center;
			background-color: #fff;
			.btns{
				margin-top: 35px;
				text-align: center;
				.btn{
					display: inline-block;
					height: 36px;
					line-height: 35px;
					padding: 0px 29px;
					border: 1px solid @themeColor;
					font-size: 16px;
					color: @themeColor;
					.border-radius(3px);
					.transition(.1s);
					&:hover{
						color: #fff;
						background-color: @themeColor;
					}
				}
				.btn_seek{
					margin-right: 92px;
				}
			}
		}
	}
}
.robot{
	position: fixed;
	top: 0px;
	height: 0px;
	width: 100%;
	.transition(.22s);
	.floor(3);
	overflow: visible;
	&.active{
		position: absolute;
	}
	.l_con{
		position: relative;
		overflow: visible;
	}
	.robot_link{
		position: absolute;
		height: 128px;
		width: 44px;
		background-color: @themeColor;
		right: -44px;
		top: 560px;
		bottom: 357px;
		cursor: pointer;
		.transition(.22s);
		.box-shadow(rgba(0,0,0,.15),0,6px,12px);
		background-image: url('../images/qna/ico_chat.png');
		background-repeat: no-repeat;
		background-position: 14px 12px;
		>span{
			display: inline-block;
			width: 14px;
			font-size: 14px;
			color: #fff;
			line-height: 17px;
			margin-left: 15px;
			margin-top: 35px;
			text-align: center;
		}
		.cover{
			.icon_close{
				position: absolute;
				top: 15px;
				width: 15px;
				right: 20px;
				height: 15px;
				background-image: url('../images/qna/ico_close.png');
			}
			.icon_custom{
				width: 24px;
				height: 25px;
				.transition(.22s);
				.imgPreLoad('../images/qna/ico_custom_hover.png');
				background-image: url('../images/qna/ico_custom.png');
			}
			.icon_file{
				width: 24px;
				height: 25px;
				.transition(.22s);
				.imgPreLoad('../images/qna/ico_file_hover.png');
				background-image: url('../images/qna/ico_file.png');
			}
			position: absolute;
			bottom: 0px;
			width: 272px;
			left: -280px;
			height: 155px;
			background-color: @w;
			.border-radius(2px);
			visibility: hidden;
			opacity: 0;
			.transition(.22s);
			.box-shadow(rgba(0,0,0,.15),0,6px,12px);
			ul{
				margin: 35px 20px 0px 20px;
				li{
					margin-bottom: 22px;
					>a{
						display: block;
						width: 100%;
						.clear;
					}
					.left{
						float: left;
					}
					.right{
						float: left;
						margin-left: 11px;
					}
					&:hover{
						.icon_custom{
							background-image: url('../images/qna/ico_custom_hover.png');
						}
						.icon_file{
							background-image: url('../images/qna/ico_file_hover.png');
						}
						.tit{
							color: @themeColor;
						}
					}
				}
			}
			.tit{
				.f(@fs2,#333333);
				.transition(.22s);
			}
			.desc{
				margin-top:6px;
				.f(@fs1,#666);
			}
		}
	}
}
//智能在线答疑提问页面
.qna_send{
	::-webkit-scrollbar {
		width: 14px;
		height: 14px;
	}
	::-webkit-scrollbar-track,
	::-webkit-scrollbar-thumb {
		border-radius: 999px;
		border: 5px solid transparent;
	}
	::-webkit-scrollbar-track {
		box-shadow: 1px 1px 5px rgba(0,0,0,.2) inset;
	}
	::-webkit-scrollbar-thumb {
		min-height: 20px;
		background-clip: content-box;
		box-shadow: 0 0 0 5px rgba(0,0,0,.2) inset;
	}
	::-webkit-scrollbar-corner {
		background: transparent;
	}
	.icon_tip{
		float: left;
		height: 19px;
		width: 19px;
		background-image: url('../images/qna/tip.png');
		margin: 10px 15px 0px 20px;
	}
	.l_con{
		width: 1130px;
		background-color: #fff;
		margin-top: 10px;
		.clear;
	}
	.tip{
		height: 40px;
		width: 1013px;
		cursor: pointer;
		margin: 22px auto;
		.border-radius(2px);
		border: 1px solid #e3d6c7;
		.f(@fs3,@themeColor,38px);
		background-color: #f9f8f6;
	}
	.editArea{
		width: 973px;
		margin: 22px auto 0px;
		border: 1px solid #c9c9c9;
		.border-radius(2px);
		height: 252px;
		padding: 8px 20px;
		.f(@fs3,#333,150%);
		display: block;
		resize: none;
		outline: none;
		.placeholder({
			.f(@fs3,#c9c9c9,150%);
		})
		// &.placeholder{
		// 	.f(@fs3,#c9c9c9,150%);
		// }
	}
	.editArea.placeholder{
		.f(@fs3,#c9c9c9,150%);
	}
	.btns{
		.clear;
		margin: 20px 0px 35px;
		text-align: center;
		font-size: 0px;
	}
	.btn_back{
		*position: relative;
		*top: -8px;
		width: 158px;
		height: 40px;
		.inline-block;
		background-color: transparent!important;
		border: 1px solid @themeColor!important;
		/*background-color: #999999;
		border:1px solid #999999;*/
		*border: none;
		.f(@fs3,@themeColor,40px);
		.border-radius(2px);
		outline: none;
		.transition(.22s);
		/*&.active{
			background-color: #33cde5;
			border: 1px solid #33cde5;
		}*/
		&:hover{
			opacity: .8;
		}
		margin-left: 25px;
	}
	.btn_submit{
		width: 158px;
		height: 40px;
		.inline-block;
		background-color: @themeColor;
		border: 1px solid @themeColor;
		.f(@fs3,@w);
		.border-radius(2px);
		outline:none;
		.transition(.22s);
		&.active{
			background-color: @themeColor;
			border: 1px solid @themeColor;
		}
		&:hover{
			opacity: .8;
		}
	}
}
.comp_tip{
	position: absolute;
	left: 50%;
	top: 722px;
}
.qna_send_succ{
	.clear;
	width: 220px;
	height: 186px;
	.rgba(0,0,0,.1);
	.border-radius(10px);
	.icon_right{
		width: 64px;
		height: 46px;
		margin: 0 auto;
		display: block;
		margin-top: 47px;
		background-image: url('../images/qna/ico_right.png');
	}
	.txt{
		margin-top: 35px;
		text-align: center;
		.f(@fs4,#666,100%);
	}
}