/**居中*/
/**IE8 不支持*/
.center(c3){
	position:absolute;
	left:50%;
	top:50%;
	.translate;
	// behavior:url(plugs/pie/PIE.htc);
}
/**居中*/
.center(@w:0,@h:0) when(default()){
	position:absolute;
	left:50%;
	top:50%;
	margin-left:-@w/2;
	margin-top:-@h/2;
	width:@w;
	height:@h;
}
.text-jusity(){
	text-align: justify;
	text-justify: inter-ideograph;
}
.transform(@val){
    -ms-transform:@val; /* IE 9 */
    -webkit-transform:@val; /* Safari and Chrome */
    -o-transform:@val;
    -ms-transform:@val;
    transform:@val;

}
/**清除浮动,控制第一行的margin-top不起作用*/
.clearfix(){
	*zoom:1;
	&:before ,&:after{
		content:"";
		display:table;
	}
	&:after{
		clear:both;
	}
}
/**兼容ie7*/
.inline-block(){
	display:inline-block;
	*display:inline; 
	*zoom:1;
}
/**弹性布局*/
.flexTab(@po:space-between,@align:center){
	display:-webkit-box;
	display:-webkit-flex;
	display:flex;
	-webkit-justify-content:@po;
	justify-content:@po;
	align-items:@align;
}
/**常用左右边距设置*/
.padd-t(@val:.31rem){
	padding-left:@val;
	padding-right:@val;
}
/**常用上下边距设置*/
.padd-v(@val:.31rem){
	padding-top:@val;
	padding-bottom:@val;
}
/**字体设置*/
.f(@size:@fs-base,@color:@g3,@lh:100%,@family:@font-family-sans-serif){
	font-size: @size;
	color: @color;
	line-height: @lh;
	font-family: @family;
}
/**字体状态设置，主要是位置，和加粗*/
.f-state(@align:center,@weight:normal){
	text-align:@align;
	font-weight:@weight;
}
/**格子系统设置@list 百分比*/
.grid(@list:@fs,@count:100,@num:1) when(@num<=length(@list)){
	>*:nth-child(@{num}){
		float:left;
		width:unit(extract(@list,@num)/@count*100,%);
	}
	.grid(@list,@count,@num+1);
}
/**等分格子设置*/
.grid-de(@num:2) when(isnumber(@num)){
	>*{
		float:left;
		width:unit(100/@num,%);
	}
}
/**规范z-index的泛滥使用，规范了7层，-2，-1,0,1,2,3,4,5*/
.floor(@layer:1,@num:0) when(@layer<=5){
	z-index:@layer*1000+@num,
} 
.floor(@layer:1,@num:0) when(@layer>5){
	z-index:99999999999;
}
/**控制多行隐藏*/
.toh(@ln:1) when(@ln=1){
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
}
.toh(@ln) when(@ln>1){
	overflow:hidden;
	word-break:break-all;
	text-overflow:ellipsis;
	display:-webkit-box; /** 对象作为伸缩盒子模型显示 **/
	-webkit-box-orient:vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
	-webkit-line-clamp:@ln; /** 显示的行数 **/
}
/**强制不换行*/
.noWrap{
	white-space:nowrap;
}
/**宽度*/
.width(@val:100%){
	width:@val;
	max-width:100%;
}
/**rgba颜色兼容性设置*/
.rgba(@r,@g,@b,@a){
	@c: rgba(@r,@g,@b,@a);
	@c2 :argb(@c);
	filter:~"progid:DXImageTransform.Microsoft.gradient(enabled='true',startColorstr='@{c2}', endColorstr='@{c2}')";
	background-color:@c;
	:root &{
		 filter:none;
	}
}
/**IE8 不支持*/
/**border-radius*函数*/
.br(@val:5px){
	-webkit-border-radius:@val;
	-moz-border-radius:@val;
	-ms-border-radius:@val;
	-o-border-radius:@val;
	border-radius:@val;
}
/**IE8 不支持*/
/**box-shadow的属性设置*/
.bs(@val){
	-moz-box-shadow:@val;
	-webkit-box-shadow:@val;
	box-shadow:@val;
}
/**IE8 不支持*/
/**过渡动画*/
.transition(@tim:.5s,@style:all,@fun:linear,@delay:0s){
	transition:@style @tim @fun @delay;
	-moz-transition:@style @tim @fun @delay;
	-webkit-transition:@style @tim @fun @delay;
	-o-transition:@style @tim @fun @delay;
}

/**IE8 不支持*/
/**盒子模型*/
.translate(@x:-50%,@y:-50%){
	transform:translate(@x,@y);
	-ms-transform:translate(@x,@y);
	-webkit-transform:translate(@x,@y);
	-o-transform:translate(@x,@y);
	-moz-transform:translate(@x,@y);
}
/**IE8 不支持*/
/**盒子模型*/
.box(@model:border-box){
	box-sizing:@model;
	-moz-box-sizing:@model; /* Firefox */
	-webkit-box-sizing:@model; /* Safari */
}
/**IE8 不支持*/
/**不能够被选取*/
.select(@val:all){
	-webkit-user-select:@val;
	-moz-user-select:@val;
	-ms-user-select:@val;
	user-select:@val;
}
/**IE8 不支持*/
/**盒子阴影*/
.box-shadow(@color:#696763,@x:2px,@y:2px,@blur:9px,@w:0px) when(iscolor(@color)){
	-moz-box-shadow:@x @y @blur @w @color;
	-webkit-box-shadow:@x @y @blur @w @color;
	box-shadow:@x @y @blur @w @color;
	// behavior:url(plugs/pie/PIE.htc);
}


.box-shadow(@val) when(@val=none){
	-moz-box-shadow:@val;
	-webkit-box-shadow:@val;
	box-shadow:@val;
}
/**IE8 不支持*/
/**border-radius*函数*/
.border-radius(@val:5px){
	-webkit-border-radius:@val;
	-moz-border-radius:@val;
	-ms-border-radius:@val;
	-o-border-radius:@val;
	border-radius:@val;
	// behavior: url(plugs/pie/PIE.htc);
}
/*兼容ie8及以下*/
.opacity(@val){
	@num:@val*100;
	-khtml-opacity:@val;
	-moz-opacity:@val;
	opacity:@val;
	filter:alpha(opacity=@num);
	filter:"alpha(opacity=@{num})";
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=@num);
}
/**IE8 不支持*/
/**input的placeholder字体设置*/
.placeholder(@a) {
	&::-webkit-input-placeholder {
		@a();
	}
	&:-moz-placeholder {
		@a();
	}
	&::-moz-placeholder {
		@a();
	}
	&:-ms-input-placeholder{
		@a();
	}
}
/**线条*/
.line(@color:lighten(@g9,27%),@weight:1px,@type:solid){
	border-bottom:@weight @type @color;
}
/**chrome 清除滚动条*/
.noScroll(){
	&::-webkit-scrollbar{display:none;};
}
/**IE8不支持*垂直渐变色*/
.bg-gradient-v(@bc,@ec){
	background:-webkit-linear-gradient(@bc,@ec);
	background:-o-linear-gradient(@bc,@ec);
	background:-moz-linear-gradient(@bc,@ec);
	background:-mos-linear-gradient(@bc,@ec);
	background:linear-gradient(@bc,@ec);
}
/**图片预加载*/
.imgPreLoad(@url){
	&:after{
		position: absolute;
		height: 0px;
		width: 0px;
		content: " ";
		background-image: url(@url);
	}
}
/**图片预加载通过before的方式*/
.imgPreLoadByBefore(@url){
	&:before{
		position: absolute;
		height: 0px;
		width: 0px;
		content: " ";
		background-image: url(@url);
	}
}
/**栅格**/
.table(@row-width:750px,@col-space:40px,@row-space:35px){
	@one:(@row-width - @col-space*5)/6;
	width:@row-width;
	*[class^='row']{
		margin-left:floor(-@col-space/2);
		margin-right:floor(-@col-space/2);
		margin-top:@row-space;
		margin-bottom:0px;
		.clear;
	}
	*[class^='col_']{
		.fl;
		margin:0px floor(@col-space/2);
	}
	.col_1{
		width:floor(@one);
	}
	.col_2{
		width:floor(@one*2+@col-space);
	}
	.col_3{
		width:floor(@one*3+@col-space*2);
	}
	.col_4{
		width:floor(@one*4+@col-space*3);
	}
	.col_5{
		width:floor(@one*5+@col-space*4);
	}
	.col_6{
		width:@row-width;
	}
}